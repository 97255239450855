<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form
          ref="conditionsFormRef"
          :model="conditions"
          inline
          size="small"
        >
          <el-form-item label="车队">
            <el-select
              v-model="conditions.fleetId"
              filterable
              :clearable="!isFleet"
            >
              <el-option
                :key="item.id"
                v-for="item in fleetOptions"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运单编号" prop="transportCode">
            <el-input v-model="conditions.transportCode" clearable> </el-input>
          </el-form-item>
          <el-form-item label="货源编号" prop="pno">
            <el-input v-model="conditions.pno" clearable> </el-input>
          </el-form-item>
          <el-form-item label="发货公司" prop="sendOwnerId">
            <el-select v-model="conditions.sendOwnerId" clearable filterable>
              <el-option
                :key="item.id"
                v-for="item in ownerOptions"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收货公司" prop="receiveOwnerId">
            <el-select v-model="conditions.receiveOwnerId" clearable filterable>
              <el-option
                :key="item.id"
                v-for="item in ownerOptions"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-collapse-transition>
            <div v-show="showMoreConditions">
              <el-form-item label="车牌号" prop="plate">
                <el-input v-model="conditions.plate" clearable> </el-input>
              </el-form-item>
              <el-form-item label="司机姓名" prop="driverPhone">
                <el-input v-model="conditions.driverPhone" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="状态" prop="status">
                <el-select v-model="conditions.status" clearable>
                  <el-option
                    v-for="(item, index) in enumStatus"
                    :key="index"
                    :value="index"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="装货日期" prop="loaddate">
                <el-date-picker
                  v-model="conditions.loaddate"
                  type="date"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="运单来源" prop="dispatchFrom">
                <el-select v-model="conditions.dispatchFrom" clearable>
                  <el-option
                    v-for="(item, index) in fromEnum"
                    :key="index"
                    :value="index"
                    :label="item"
                  ></el-option>
                </el-select>
              </el-form-item></div
          ></el-collapse-transition>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
            <el-button
              type="info"
              plain
              class="buttons"
              @click="handleReset('conditionsFormRef')"
              >重置</el-button
            >
            <el-button
              type="primary"
              plain
              class="buttons"
              @click="handleCollapse"
              >{{ showMoreConditions ? "收起" : "更多" }}</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-dropdown
              trigger="click"
              style="margin-right: 10px"
              @command="handleCommand"
            >
              <el-button type="primary">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">全部导出</el-dropdown-item>
                <el-dropdown-item command="b">批量导出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table
          border
          :data="tableList"
          style="width: 100%"
          ref="tableRef"
          row-key="transportCode"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            reserve-selection
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            min-width="180"
            show-overflow-tooltip
            label="运单编号"
            prop="transportCode"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货源编号"
            min-width="150"
            prop="pno"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="发货公司"
            prop="sendOwner"
            min-width="120"
          >
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            label="收货公司"
            prop="receiveOwner"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="车牌号"
            prop="plate"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="司机姓名"
            prop="driverName"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip label="车队" prop="fleetName">
          </el-table-column>
          <!--<el-table-column show-overflow-tooltip label="毛重" prop="roughWeight">
          </el-table-column>
          <el-table-column show-overflow-tooltip label="皮重" prop="tare">
          </el-table-column>
          <el-table-column show-overflow-tooltip label="净重" prop="suttle">
          </el-table-column>-->
          <el-table-column
            show-overflow-tooltip
            label="装货时间"
            prop="loaddate"
            min-width="110"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip label="来源" min-width="110">
            <template slot-scope="scope">
              {{ fromEnum[scope.row.dispatchFrom] }}
            </template>
          </el-table-column>

          <el-table-column show-overflow-tooltip label="状态">
            <template slot-scope="scope">
              <el-tag
                size="small"
                :type="enumStatus[scope.row.status].type"
                v-if="enumStatus[scope.row.status]"
                >{{ enumStatus[scope.row.status].label }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="重量"
            prop="tons"
          ></el-table-column>
          <el-table-column show-overflow-tooltip label="运费" prop="amount">
          </el-table-column>
          <el-table-column label="操作" min-width="120" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                v-show="scope.row.mergeStatus == 21"
                @click.stop="handleAddPoundBill(scope.row)"
              >
                添加磅单
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click.stop="goDetails(scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
    <AddPoundBillDialog
      ref="addPoundBillDialogRef"
      v-if="addPoundVisible"
      @confirm="handlePoundConfirm"
      @cancel="handlePoundCancel"
    ></AddPoundBillDialog>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import AddPoundBillDialog from "../module/addPoundBillDialog";
export default {
  data() {
    return {
      isFleet: this.$route.query.isFleet ? this.$route.query.isFleet : false,
      fleetOptions: [],
      conditions: {
        fleetId: "",
        page: 1,
        limit: 10,
        sendOwnerId: "",
        receiveOwnerId: "",
        status: "",
        transportCode: "",
        pno: "",
        dispatchFrom: "",
        loaddate: "",
      },
      showMoreConditions: false,
      checkedIds: [],
      enumStatus: {
        10: { label: "待审核", type: "warning" },
        11: { label: "未通过", type: "info" },
        20: { label: "待起运" },
        21: { label: "待送达" },
        22: { label: "已完工", type: "success" },
      },
      fromEnum: {
        1: "货主",
        2: "车队",
        3: "抢单",
      },
      ownerOptions: [],
      tableList: [],
      total: 0,
      addPoundVisible: false,
    };
  },
  // computed: {
  //   ...mapState(["conditionsList"]),
  // },
  components: {
    AddPoundBillDialog,
    Pagination,
  },
  created() {
    console.log(this.$route.query.isFleet);
    this.getOwnerOptions();
    if (this.isFleet) {
      this.getOwnFleetOptions();
    } else {
      this.getFleetOptions();
      this.getList();
    }
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    // ...mapMutations(["createConditions"]),
    async getOwnerOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/list",
        "get",
        {}
      );
      this.ownerOptions = data.data.list;
    },
    async getFleetOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/FleetInfo/list",
        "get",
        {}
      );
      this.fleetOptions = data.data.list;
    },
    async getOwnFleetOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/FleetInfo/ownList",
        "get",
        {}
      );
      this.fleetOptions = data.data;
      if (this.fleetOptions.length > 0) {
        this.conditions.fleetId = this.fleetOptions[0].id;
        this.getList();
      }
    },
    handleReset(formName) {
      if (!this.isFleet) {
        this.conditions.fleetId = "";
      }
      this.$refs.tableRef.clearSelection();
      this.$refs[formName].resetFields();
      this.conditions.limit = 10;
      this.conditions.page = 1;
      this.getList();
      this.showMoreConditions = false;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    // 获取列表数据
    async getList() {
      if (this.isFleet && !this.conditions.fleetId) {
        return this.$message.error("暂无关联车队");
      }
      const { data } = await this.$http(
        "api1",
        "/api/biz/TransportBill/list",
        "get",
        this.conditions
      );
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableList = data.data.list;
      this.total = data.data.totalCount;
    },
    // 获取货场列表
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    goDetails(row) {
      this.$router.push({
        path: "/transportBill/transportBillDetails",
        query: { id: row.planId, transportBillId: row.id },
      });
    },
    handleAddPoundBill(row) {
      this.addPoundVisible = true;
      this.isEdit = false;
      this.$nextTick(() => {
        this.$refs.addPoundBillDialogRef.transportBillId = row.id;
        this.$refs.addPoundBillDialogRef.form.driverPhone = row.driverPhone;
        this.$refs.addPoundBillDialogRef.form.plate = row.plate;
      });
    },
    handlePoundConfirm() {
      this.getList();
      this.addPoundVisible = false;
    },
    handlePoundCancel() {
      this.addPoundVisible = false;
    },
    handleCollapse() {
      this.showMoreConditions = !this.showMoreConditions;
    },
    async handleCommand(command) {
      if (this.isFleet && !this.conditions.fleetId) {
        return this.$message.error("暂无关联车队");
      }
      if (command == "a") {
        this.$download(
          "post",
          "/api/biz/TransportBill/listExport",
          this.conditions
        );
      } else if (command == "b") {
        if (!this.checkedIds || this.checkedIds.length == 0) {
          return this.$message.error("请选择要导出的数据");
        }
        this.$download("post", "/api/biz/TransportBill/listExport", {
          ids: this.checkedIds.toString(),
        });
      }
    },
    handleSelectionChange(val) {
      this.checkedIds = val.map((item) => item.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
